body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.w-full {
  width: 100%;
}

.text-center {
  text-align: center;
}

.login-form {
  min-width: 300px;
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.last-item {
  margin-bottom: 0px;
}

.home-body {
  margin: 0.5rem;
  min-height: calc(100vh - 1rem);
  display: flex;
}

.utils {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.w-full {
  width: 100%;
}

.logout-btn {
  background-color: #001529;
  padding: 1rem;
  color: white;
  cursor: pointer;
}

.details {
  padding: 0.2rem;
  margin-bottom: 0.5rem;
}

.details-header {
  padding: 0.6rem;
  background: #f5f5f5;
  border: 1px solid #dddddd;
}

.details-row {
  display: flex;
  padding: 0.5rem;
  border-bottom: 1px solid #dddddd;
}

.details-field {
  width: 150px;
}

.details-description {
  flex: 1;
}

.back-btn {
  display: flex;
  width: calc(100% - 1rem);
  justify-content: flex-end;
  padding: 0.5rem;
  cursor: pointer;
}

.my-table .ant-table-cell {
  white-space: nowrap;
}

.p-1 {
  padding: 1rem;
}

.total {
  float: right;
}